body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scroll__container{
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
}

.move_rank__container{
  position: fixed;
  right: 5%;
  bottom: 12%;
  z-index: 1;
}

.candidate_double_button{
  padding:"10px";
  margin-left:"5px";
  margin-right:"5px";
}

.iframe__container{
  position: fixed;
  right: 2%;
  bottom: 20%;
  z-index: 1;
}

#top{
  font-weight: bold;
  font-size: 15px;
  padding :15px 13px;
  background-color: rgb(122, 122, 122);
  color:#fff;
  border: 1px solid rgb(210, 204, 193);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

#top:hover{
  color :rgb(0, 0, 0);
}

